const financial = require("./finicialNumbers")

function numberWithComma(number) {
    if (!number) {
        return 0
    } else {
        return Number(financial(number)).toLocaleString('en-US')
    }
}

module.exports = numberWithComma