<template>
  <v-container fluid class="down-top-padding">
    <v-card class="white pa-3">
      <h1 class="text-center subtitle-4 black--text">اضافة سائق</h1>
      <v-form v-model="isFormValid">
        <v-row class="mt-5">
          <!-- name -->
          <v-col cols="12" md="4">
            <v-text-field v-model="addData.name" :rules="Rules.required" dense label="الاسم" outlined></v-text-field>
          </v-col>
          <!-- mobile -->
          <v-col cols="12" md="4">
            <v-text-field v-model="addData.mobile" :rules="Rules.required" dense label="الهاتف" outlined></v-text-field>
          </v-col>
          <!-- gender -->
          <v-col cols="12" md="4">
            <v-select v-model="addData.gender" :items="gender_select" dense label="الجنس" outlined></v-select>
          </v-col>
          <!-- password_show -->
          <v-col cols="12" md="4">
            <v-text-field v-model="addData.password_show" :rules="Rules.required" dense label="الرمز"
              outlined></v-text-field>
          </v-col>
          <!-- birthday -->
          <v-col cols="12" md="4">
            <v-menu v-model="birthday_menu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="addData.birthday" label="تاريخ الميلاد" readonly v-bind="attrs" v-on="on" dense
                  outlined clearable :rules="Rules.required"></v-text-field>
              </template>
              <v-date-picker v-model="addData.birthday" @input="birthday_menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <!-- address -->
          <v-col cols="12" md="4">
            <v-text-field v-model="addData.address" :rules="Rules.required" dense label="العنوان" outlined></v-text-field>
          </v-col>
          <v-col cols="12"></v-col>
          <!-- upload img -->
          <v-col cols="12" md="4">
            <image-upload-base64-vue imageName="img" btnText="صورة السائق" :isDeletable="true"
              v-on:imageUploaded="addData.img = $event" :setToNull="setToNull"></image-upload-base64-vue>
          </v-col>
          <!-- upload img_id_front -->
          <v-col cols="12" md="4">
            <image-upload-base64-vue imageName="img_id_front" btnText="صور الهوية الامامية" :isDeletable="true"
              v-on:imageUploaded="addData.img_id_front = $event" :setToNull="setToNull"></image-upload-base64-vue>
          </v-col>
          <!-- upload img_id_back -->
          <v-col cols="12" md="4">
            <image-upload-base64-vue imageName="img_id_back" btnText="صور الهوية الخلفية" :isDeletable="true"
              v-on:imageUploaded="addData.img_id_back = $event" :setToNull="setToNull"></image-upload-base64-vue>
          </v-col>
          <!-- note -->
          <v-col cols="12" md="12">
            <v-textarea rows="2" v-model="addData.note" dense label="الملاحظة" outlined></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-center mt-5">
        <v-btn :loading="addBtnLoading" :disabled="!isFormValid" color="newPrimary" class="white--text"
          @click="submitAdd()">
          اضافة
        </v-btn>
        <v-btn class="ml-5 newPrimary--text" text @click="cancelAdd()">
          الغاء
        </v-btn>
      </div>
    </v-card>
    <!--- respondMessageVue -->
    <respondMessageVue :dialogData="dialogData" :retreat_page="retreat_page"></respondMessageVue>
  </v-container>
</template>

<script>
// ES modules
import driversApi from '@/api/drivers'
import numberWithComma from '@/constants/number'
import respondMessageVue from '@/components/respondMessage.vue'
import imageUploadBase64Vue from '@/components/imageUpload'
import randPass from '@/constants/rand_pass'

export default {
  name: 'addSubAdmin',
  components: {
    respondMessageVue,
    imageUploadBase64Vue,
  },

  data: () => ({
    isFormValid: false,

    setToNull: false,

    retreat_page: '/dashboards/customers',

    addBtnLoading: false,

    date_of_contract_menu: false,

    gender_select: ['ذكر', 'انثى'],

    birthday_menu: false,

    addData: {
      name: null,
      mobile: null,
      address: null,
      current_class: null,
      img: null,
      img_id: [],
      img_id_back: null,
      img_id_front: null,
      gender: "ذكر",
      note: null,
      birthday: null,
      password_show: randPass()
    },

    Rules: {
      required: [value => !!value || 'الحقل مطلوب']
    },

    dialogData: {
      open: false,
      color: 'info',
      bodyText: 'test'
    }
  }),

  methods: {
    async submitAdd() {

      if (!this.addData.img) {
        return this.showDialogfunction('يجب اضافة صورة السائق', '#FF5252')
      }

      if (!this.addData.img_id_front) {
        return this.showDialogfunction(
          'يجب اضافة صورة الهوية الامامية',
          '#FF5252'
        )
      }

      if (!this.addData.img_id_back) {
        return this.showDialogfunction(
          'يجب اضافة صورة الهوية الخلفية',
          '#FF5252'
        )
      }

      this.addBtnLoading = true

      const response = await driversApi.add({
        name: this.addData.name,
        mobile: this.addData.mobile,
        address: this.addData.address,
        current_class: this.addData.current_class,
        img: this.addData.img,
        password_show: this.addData.password_show,
        img_id: [
          this.addData.img_id_front,
          this.addData.img_id_back],
        birthday: this.addData.birthday,
        note: this.addData.note,
        gender: this.addData.gender
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addBtnLoading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.addBtnLoading = false

        this.addData.name = null
        this.addData.name_en = null
        this.addData.mobile = null
        this.addData.address = null
        this.addData.current_class = null
        this.addData.img = null
        this.addData.password_show = null
        this.addData.img_id = []
        this.addData.img_id_back = null
        this.addData.img_id_front = null
        this.addData.note = null
        this.addData.birthday = null
        this.setToNull = true

        this.showDialogfunction(response.data.message, 'info')
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    isNumberWithComma(evt) {
      const keysAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.'
      ]
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    cancelAdd() {
      window.history.back()
    },

    randPass,

    numberWithComma
  }
}
</script>
