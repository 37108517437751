import axios from "axios";
import { sha512 } from "js-sha512";

class driversApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/drivers?page=${page}&limit=${limit}&search=${search}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async getAll() {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/drivers/all`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async add({
    name,
    mobile,
    address,
    note,
    img,
    img_id,
    birthday,
    password_show,
    gender,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post("/drivers", {
        name,
        mobile,
        address,
        note,
        img,
        img_id,
        birthday,
        password: sha512(password_show),
        password_show,
        gender,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async edit({
    id,
    name,
    mobile,
    address,
    note,
    img,
    old_img,
    img_id,
    old_img_id,
    birthday,
    password_show,
    gender,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .put("/drivers", {
        _id: id,
        name,
        mobile,
        address,
        note,
        img,
        old_img,
        img_id,
        old_img_id,
        birthday,
        password: sha512(password_show),
        password_show,
        gender,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .delete(`/drivers/id/${id}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }
}

export default new driversApi();
