<template>
  <div class="d-flex align-center">
    <v-btn text color="newPrimary" @click="chooseImage()"> {{ btnText }}</v-btn>

    <input v-show="false" :id="'fileUpload_' + imageName" type="file" @change="fileInputChange($event)"
      accept="image/png, image/gif, image/jpeg, image/jpg" />
    <v-spacer></v-spacer>
    <div class="img_container">
      <v-img class="image_upload_table" :src="getUrl()" />
      <v-icon v-show="(srcImg && isDeletable === true) || (DBLink && isDeletable === true)
        " class="close_img" color="red" @click="emptyImg()">mdi-close-circle</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'imageUploadVue',

  props: {
    btnText: {
      type: String,
      default: 'Upload'
    },
    imageName: {
      type: String,
      default: 'image'
    },

    isDeletable: {
      type: Boolean,
      default: true
    },

    DBLink: {
      type: String,
      default: null
    },

    setToNull: {
      type: Boolean,
      default: false
    },

    content_url: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      newImg: null,
      srcImg: null
    }
  },

  created() {
    this.$emit('imageUploadedNew', null)
  },

  watch: {
    setToNull: {
      handler() {
        if (this.setToNull === true) {
          this.emptyImg()
        }
      }
    }

  },

  methods: {
    chooseImage() {
      document.getElementById('fileUpload_' + this.imageName).click()
    },

    async fileInputChange(event) {
      this.newImg = null

      const file = event.target.files[0]

      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })

      // const regex = /data:.*base64,/;
      const base64 = await toBase64(file)
      this.newImg = base64

      this.srcImg = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.srcImg = URL.createObjectURL(file)
        } else {
          console.log('error_image_add')
        }
      } else {
        this.srcImg = null
      }
      this.$emit('imageUploadedNew', this.newImg)
      this.$emit('imageUploaded', this.newImg)
      this.$emit('removeImg', this.DBLink)
    },

    emptyImg() {
      document.getElementById('fileUpload_' + this.imageName).value = ''
      this.srcImg = null
      this.DBLink = null
      this.$emit('imageUploadedNew', null)
      this.$emit('imageUploaded', null)
      this.$emit('removeImg', null)
    },

    getUrl() {
      if (this.srcImg) {
        return this.srcImg
      } else if (this.DBLink) {
        const url = `${this.content_url}?width=${100}&height=${100}&img=${this.DBLink
          }`

        return url
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
.img_container {
  width: 70px;
  height: 70px;
  border: 1px solid red;
  position: relative;
}

.close_img {
  position: absolute;
  top: -23px;
  left: -13px;
}

.image_upload_table {
  cursor: pointer;
  width: 70px;
  height: 70px;
}
</style>
